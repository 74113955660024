import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FeedbackIcon from "@mui/icons-material/Feedback";
import "../Footer/Footer.css";
import StickyFooter from "./StickyFooter";
import IMAGES from "../../img/image";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      {/* <div className="footer-subscribe">
        <span>Follow Us!</span>
        <h2>For Every Latest News.</h2>
        <div className="social-media-links social">
          <Link
            target="_blank"
            to="https://whatsapp.com/channel/0029VaoB1ByIiRoxPstu7j3e"
          >
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
      </div> */}
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/918638612227">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="footer-logo">
              <b>
                Recharge<span className="ffca00">Factory</span>
              </b>
            </div>
            <span>
              <small>
                Welcome to Recharge Factory! Discover in-game currencies for
                Mobile Legends, BGMI, PUBG, Genshin Impact, and more. Enjoy
                fast, reliable service and exceptional customer support.
              </small>
            </span>
            <br />
            <br />
            <p className="m-0 mt-2" style={{ color: `var(--d)` }}>
              SUPPORT
            </p>
            <span>+91 8638612227</span>
            <br />
            <span>csrechargefactory227@gmail.com</span>
          </div>
          <div className="px-lg-5 quick-links col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/support">Customer Support</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <h6>Payment Modes</h6>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <div className="payment-channels">
                <img src={IMAGES.phonepe} alt="" />
                <img src={IMAGES.gpay} alt="" />
                <img src={IMAGES.upi} alt="" />
              </div>
              <div>
                <span className="me-2">Follow Us</span>
                <Link
                  target="_blank"
                  to="https://whatsapp.com/channel/0029VaoB1ByIiRoxPstu7j3e"
                >
                  <WhatsAppIcon className="icon waicon" />
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <span className="text-lg-center text-start">
            <small>
              All Rights Reserved © 2024 | RECHARGE FACTORY |{" "}
              <br className="d-block d-md-none d-lg-none" /> Website Developed
              by{" "}
              <Link
                style={{
                  color: `var(--d)`,
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
                target="_blank"
                to="https://aashirdigital.com"
              >
                ~aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
      {/* <StickyFooter /> */}
    </React.Fragment>
  );
};

export default Footer;
